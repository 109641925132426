<template>
  <v-list-item :key="index">
    <v-list-item-avatar v-if="pIcon">
      <v-icon :color="pColor">
        {{ pIcon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-icon class="fixed-width">
      <v-chip small :color="notification.error ? 'error t-bw-error--text' : 'success t-bw-success--text'"> {{ $t('menu.stock-report').toUpperCase() }} </v-chip>
    </v-list-item-icon>
    <v-list-item-content class="d-flex justify-start">
      <p style="font-size: 14px" class="ma-0 pa-0 my-1 black--text">
        {{ notification.error ? $t('reports.titles.errorGeneratedReport') : $t('reports.titles.generatedReport') }}
      </p><br/>
      <v-list-item-title class="d-block">
        <p class="ma-0 pa-0 my-1" style="font-size: 12px; color: #0f0d0d; text-align: start;">{{ getDateFormat(notification.dateTime) }}</p>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="withAction">
      <v-btn v-if="!notification.watched" fab x-small color="grey lighten-4" elevation="0" @click="$emit('mark:read')" :loading='loading'>
        <template v-slot:loader>
          <v-progress-circular indeterminate size="12" width="2" />
        </template>
        <v-icon color="neuter" small>
          mdi-check
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
  name: 'StockNotification',

  props: {
    index: {
      type: Number,
      require: true
    },
    notification: {
      type: Object,
      require: true
    },
    loading: {
      type: Boolean,
      require: false,
      default: false
    },
    loadingSee: {
      type: Boolean,
      require: false,
      default: false
    },
    pColor: {
      type: String,
      require: false
    },
    pIcon: {
      type: String,
      require: false
    },
    withAction: {
      type: Boolean,
      require: false,
      default: true
    }
  },

  data: () => ({}),

  computed: {
    ...mapGetters({
      documentType: "storage/documentTypes",
      date: "ui/GET_DATE_FILTERS"
    })
  },

  methods: {
    getDateFormat(dateString) {
      return moment(new Date(dateString)).format(this.date.dateFormat)
    },
  }
}
</script>

<style scoped>
.fixed-width {
  width: 9vw;
  max-width: 100%;
  margin: auto 32px auto 0;
}

.v-chip.v-size--small,
.v-chip.v-size--x-small {
  border-radius: 0.3em !important;
}

.tooltip-styles {
  width: 40vh;
  max-width: 100%;
  background-color: #232323;
  opacity: 90%;
}
</style>