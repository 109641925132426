import i18n from "@/plugins/i18n";
import * as types from "./mutations-type.js";
import ProductService from "../../../../services/ProductService";

export default {
  loadingON({ commit, state }) {
    commit(types.SET_LOADING_ON, true);
  },
  loadingOFF({ commit }) {
    commit(types.SET_LOADING_OFF, false);
  },
  setProductList({ commit }, items) {
    commit(types.SET_PRODUCT_LIST, items);
  },
  async fetchListProduct({ dispatch, commit, state }, [filters, toast]) {
    state.products = [];
    dispatch("loadingON");
    try {
      const { data } = await ProductService.products(filters);
      commit(types.SET_PRODUCT_LIST, data);
      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("product.notification.listError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
    }
  },
  async fetchListProductByRecord({ dispatch, commit, state }, [filters, toast]) {
    state.products = [];
    dispatch("loadingON");
    try {
      const { data } = await ProductService.productsByRecord(filters);
      commit(types.SET_PRODUCT_LIST_FOR_PRINT, data);
      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("product.notification.listError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
    }
  },
  async exportCSV({ dispatch, commit, state }, [language, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await ProductService.export(language);
      const FileSaver = require("file-saver");
      const blob = new Blob([data], {
        type: "application/xlsx",
      });
      FileSaver.saveAs(blob, "products_data.xlsx");

      toast.success(i18n.t("product.notification.export"), {
        icon: 'mdi-check-circle',
        queueable: true,
      });

      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("product.notification.exportError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });

      dispatch("loadingOFF");
    }
  },
  async saveProduct(
    { dispatch, commit, state },
    [item, documents, editOrSave, toast]
  ) {
    dispatch("loadingON");
    try {
      await ProductService.save(item, documents, editOrSave);
      toast.success(i18n.t("product.notification.save"), {
        queueable: true,
        icon: 'mdi-check-circle',
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.info(i18n.t("product.notification.inUse"), {
          icon: 'mdi-information-slab-circle',
          queueable: true,
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("product.notification.saveError"), {
            icon: "mdi-alert-circle",
            queueable: true,
          });
      }
    }
  },
  async deleteProduct({ commit, state }, [id, toast]) {
    try {
      await ProductService.delete(id);
      toast.success(i18n.t("product.notification.deleted"), {
        queueable: true,
        icon: 'mdi-check-circle',
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.info(i18n.t("product.notification.inUse"), {
          icon: 'mdi-information-slab-circle',
          queueable: true,
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("product.notification.deletedError"), {
            icon: "mdi-alert-circle",
            queueable: true,
          });
      }
    }
  },
};
