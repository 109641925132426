<template>
  <v-list-item :key="index">
    <v-list-item-avatar v-if="pIcon">
      <v-icon :color="pColor">
        {{ pIcon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-icon class="fixed-width">
      <v-chip small :class="{ 'success t-bw-success--text':notification.success, 'error t-bw-error--text':!notification.success }"><strong> {{ $t('modules.name.Record').toUpperCase() }} </strong></v-chip>
    </v-list-item-icon>
    <v-list-item-content class="d-flex justify-start">
      <p style="font-size: 14px" class="ma-0 pa-0 my-1 black--text">
        {{ notification.success ? $t('general.notification.importRecordsSuccess') : $t('general.notification.importRecordsError') }}
      </p><br/>
      <v-list-item-title class="d-block">
        <p class="ma-0 pa-0 my-1" style="font-size: 12px; color: #0f0d0d; text-align: start;">{{ getDateFormat(notification.dateTime) }}</p>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action class="d-inline justify-space-between align-center align-content-center" v-if="withAction">
      <v-btn v-if="!notification.success" class='mx-1' fab x-small color="grey lighten-4" elevation="0" @click="downloadFileFromS3(notification)" :loading='loadings.download || loading'>
        <template v-slot:loader>
          <v-progress-circular indeterminate size="12" width="2" />
        </template>
        <v-icon small>
          mdi-download-multiple
        </v-icon>
      </v-btn>
      <v-btn v-if="!notification.watched" fab x-small color="grey lighten-4" elevation="0" @click="$emit('mark:read')" :loading='loadings.read || loading'>
        <template v-slot:loader>
          <v-progress-circular indeterminate size="12" width="2" />
        </template>
        <v-icon color="neuter" small>
          mdi-check
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import moment from "moment/moment";
import {mapGetters} from "vuex";
import axios from "axios";
import i18n from '@/plugins/i18n';

export default {
  name: 'ImportRecordNotification',

  props: {
    index: {
      type: Number,
      require: true
    },
    notification: {
      type: Object,
      require: true
    },
    loading: {
      type: Boolean,
      require: false,
      default: false
    },
    loadingSee: {
      type: Boolean,
      require: false,
      default: false
    },
    pColor: {
      type: String,
      require: false
    },
    pIcon: {
      type: String,
      require: false
    },
    withAction: {
      type: Boolean,
      require: false,
      default: true
    }
  },

  data: () => ({
    loadings: {
      read: false,
      download: false
    }
  }),

  computed: {
    ...mapGetters({
      documentType: "storage/documentTypes",
      date: "ui/GET_DATE_FILTERS"
    })
  },

  methods: {
    async downloadFileFromS3(notification) {
      const filename = `${this.$t('modules.name.Record').toLowerCase()}.xlsx`;

      await axios({
        url: notification.s3Url,
        method: 'GET',
        responseType: 'blob',
      })
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);

          fileLink.click();

          document.body.removeChild(fileLink);
          window.URL.revokeObjectURL(fileURL);
        })
        .catch(error => {
          console.error('Error al descargar el archivo: ', error);
        });
    },
    getDateFormat(dateString) {
      return moment(new Date(dateString)).format(this.date.dateFormat)
    }
  }
}
</script>

<style scoped>
.fixed-width {
  width: 9vw;
  max-width: 100%;
  margin: auto 32px auto 0;
}

.v-chip.v-size--small,
.v-chip.v-size--x-small {
  border-radius: 0.3em !important;
}

.tooltip-styles {
  width: 40vh;
  max-width: 100%;
  background-color: #232323;
  opacity: 90%;
}
</style>