import AuthService from '@/services/AuthService'
import LanguajeService from "../../../services/LanguajeService";
import i18n from '@/plugins/i18n'
import * as types from './mutations-type.js'

export default {
  loadingON({ commit, state }) {
    commit(types.SET_LOADING_ON, true)
  },
  loadingOFF({ commit }) {
    commit(types.SET_LOADING_OFF, false)
  },
  async login({ dispatch, commit, state }, toast) {
    dispatch('loadingON')

    await AuthService.login(state.credentials).then(async (res) => {
      commit(types.LOGIN, [res.data, toast]);

      console.log('>>> fetch available companies')
      await dispatch('company/fetchAvailableCompanies', [], { root: true }).then(async (response) => {
        console.log('>>> fetch init data', response)
        if (response) {
          await AuthService.getProfile().then((resp) => {
            state.roles = resp.data.roles;
            localStorage.setItem('roles', JSON.stringify(resp.data.roles));
            LanguajeService.changeLanguaje(resp.data.language);
            localStorage.setItem('profile', JSON.stringify(resp.data));
          });
          
          await dispatch('general/getInitData', [res.data[1], false], { root: true }).then(() => {
            dispatch('setFilters')
          });
        }
      })
    }).catch((e) => {
      console.log(e);
      toast.error(i18n.t('auth.notification.loginError'), {
        icon: "mdi-alert-circle",
        queueable: true,
      })
    }).finally(() => {
      dispatch('loadingOFF')
    });
  },
  async loadInitData({ dispatch, state }) {
    dispatch('loadingON')

    await AuthService.getProfile().then(async (resp) => {
      LanguajeService.changeLanguaje(resp.data.language);
      state.roles = resp.data.roles;
      localStorage.setItem('roles', JSON.stringify(resp.data.roles));
      localStorage.setItem('profile', JSON.stringify(resp.data));
    }).finally(() => {
      dispatch('loadingOFF')
    });
  },
  setFilters({ commit }) {
    commit('ui/FILTERS_ORIGINAL_STATE', false, { root: true })
    commit('ui/CLEAR_LOCAL_FILTERS_FOR_PRODUCER', false, { root: true })
    commit('ui/FILTERS_OBJECTS_ORIGINAL_STATE', false, { root: true })

    commit('ui/SET_LOCAL_STORAGE_FOR_INSPECTIONS', false, { root: true })
    commit('ui/SET_LOCAL_STORAGE_FOR_RECORDS', false, { root: true })
    commit('ui/SET_LOCAL_STORAGE_FOR_PRE_RECORDS', false, { root: true })
    commit('ui/SET_LOCAL_STORAGE_FOR_DOCUMENTS', false, { root: true })
    commit('ui/SET_LOCAL_STORAGE_FOR_CONTRACTS', false, { root: true })
    commit('ui/SET_LOCAL_STORAGE_FOR_SELLERS', false, { root: true })
    commit('ui/SET_LOCAL_STORAGE_FOR_LOCATIONS', false, { root: true })
    commit('ui/SET_LOCAL_STORAGE_FOR_STORAGES', false, { root: true })
    commit('ui/SET_LOCAL_STORAGE_FOR_PRODUCER', false, { root: true })
  },
  logout({ commit }, [toast, message]) {
    commit(types.LOGOUT)
    if (message) {
      toast.success(i18n.t('auth.notification.logout'), {
        icon: 'mdi-check-circle',
        queueable: true
      })
    }
  },
  async refresh({ commit }, [refresh_token, token]) {
    const { data } = await AuthService.refreshToken(refresh_token, token)
    commit(types.REFRESH, data)
  },
}
