import { default as http } from './HttpService'
import BaseService from "@/services/BaseService"

class AppointmentsService extends BaseService {
  constructor() {
    super()
  }

  recordById(id) {
    return http.client.get('/v1/record/' + id)
  }

  recordsByIds(ids) {
    return http.client.post('/v1/record/by_ids', {
      record_ids: ids,
    })
  }

  appointments(filters) {
    return http.client.post('/v1/record/filter/with-structure-value', filters)
  }

  // in use
  getAppointmentsByLoteOld(lot, source) {
    return http.client.post(`v1/record/filter/lote`, { lote: lot }, {
      cancelToken: source.token,
    })
  }

  getAppointmentsIDS(filters, type, source) {
    switch (type) {
      case 'rastreability':
        return http.client.post(`v1/record/filter/lote/table`, filters)
      case 'recall':
        return http.client.post(`v1/record/filter/lote/table/recall`, filters)
    }
  }

  appointmentsByCompany() {
    return http.client.get(`v1/company/records`)
  }

  // in use
  appointmentsByCompanyPaginatedGeneralReport(pagination, source) {
    return http.client.post(`v1/record/records-paginated-to-report`, pagination, {
      cancelToken: source.token,
    })
  }

  // in use
  appointmentsByCompanyPaginated(pagination, source) {
    return this.requestWithCancellation('post', `v1/record/records-paginated`, pagination, 'appointmentsByCompanyPaginated')
  }

  // in use
  appointmentsByCompanyFiltered(filters) {
    return http.client.post(`v1/record/records-filters`, filters)
  }

  cantToExport(filters) {
    return http.client.post(`v1/record/records-export-count`, filters)
  }

  filterSearch(data, source) {
    return http.client.post(`/v1/record/string_filter`, data, {
      cancelToken: source.token,
    })
  }

  // in use
  appointmentsByProcess(process_id) {
    return http.client.post(`v1/record/filter/proccess/${process_id}`)
  }

  // in use
  appointmentsByIds(filters) {
    return http.client.post(`v1/record/by_ids`, filters)
  }

  export(filters, records, type, isAsync) {
    let url = '';

    switch (type) {
      case 'excel':
        url = '/v1/record/records-filters/excel';
        break;
      case 'pdf':
        url = '/v1/record/records-filters/pdf';
        break;
      case 'pdfTraceability':
        url = '/v1/record/records-traceability/pdf';
        break;
      default:
        throw new Error('Unsupported type');
    }

    if (isAsync) {
      url += '/async';
    }

    return http.client.post(url, filters, {
      responseType: 'blob',
    });
  }

  exportErrors(recordId, lang) {
    return http.client.get(`/v1/record/export-validation-errors/${recordId}/${lang}`, {
      responseType: 'blob',
    })
  }

  import(options) {
    let url = 'v1/record/excel_import';
    let fd = new FormData();

    fd.append('process_id', options.process);
    fd.append('language', options.language);
    fd.append('file', options.file);

    if (options.isAsync) {
      url += '/async';
      return http.client.post(url, fd, { headers: { 'Content-Type': 'multipart/form-data' } });
    }

    return http.client.post(url, fd, { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' })
      .then(response => {
        const contentType = response.headers['content-type'];

        if (contentType.includes('application/octet-stream')) {
          return response.data;
        }

        if (contentType.includes('application/json')) {
          return { status: 200, statusText: 'OK' };
        }

        throw new Error('Unexpected content type');
      });
  }

  fetchTemplate(options) {
    let fd = new FormData()

    fd.append('process_id', options.process)
    fd.append('language', options.language)

    return http.client.post(`v1/record/records-filters/generate-template`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'blob',
    })
  }

  updateEvidencesByRecord(id, document_conf, currents, documents) {
    let fd = new FormData()
    fd.append('evidences_ids', JSON.stringify({ documentIds: currents }))

    if (documents.length > 0) {
      fd.append('document_json', JSON.stringify(document_conf))

      _.forEach(documents, (file) => {
        fd.append('documents_file', file)
      })
    }

    return http.client.put(`/v1/record/${id}/put-evidences`, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  fetchEvidencesByRecord(id) {
    return http.client.get(`/v1/record/${id}/show-evidences`)
  }

  // in use
  save(record, document, documents_file, editOrSave, pre_record_id) {
    let fd = new FormData()
    fd.append('pre_record_id', pre_record_id)
    fd.append('record_json', JSON.stringify(record))
    fd.append('document_json', JSON.stringify(document))
    if (documents_file.length > 0) {
      _.forEach(documents_file, (file) => {
        fd.append('documents_file', file)
      })
    }
    if (editOrSave > -1) {
      return http.client.put('/v1/record/update', fd, { headers: { 'Content-Type': 'multipart/form-data' } })
    } else {
      return http.client.post(`v1/record/create`, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
    }

  }

  delete(id) {
    return http.client.delete('/v1/record/' + id)
  }

  checkBurdens(options) {
    let fd = new FormData()
    fd.append('burdens', options.file)

    return http.client.post(`v1/general-traceability/validate-burdens`, fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob',
      },
    )
  }
}

export default new AppointmentsService()
