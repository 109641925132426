import _ from "lodash";
import StructuresService from "@/services/StructuresService";
import LanguageService from "@/services/LanguajeService";
import OrderListService from "@/services/OrderListService";

export default {
  data() {
    return {
      items: [],
      selected: [],
      searchQuery: '',
      pageableDTO: {
        page: 0,
        size: 10,
        sortBy: "id",
        direction: "desc"
      },
      totalFiltered: 0,
      totalGeneral: 0,
      totalSelected: 0,
      totalPages: 0,
      loading: false,
      selectAll: 'undefined',
    };
  },
  methods: {
    async fetchItems() {
      const filters = {
        stringFilter: this.searchQuery || null,
        criteria: this.selectedFilter || null,
        pageableDTO: {
          ...this.pageableDTO,
        }
      };

      this.loading = true;

      try {
        const { data } = await StructuresService.structuresLot(filters);

        this.items = _.map(data.content, (r) => ({
          ...r,
          name: this.translate(r.name)
        }));

        this.items.sort((a, b) => OrderListService.customSort(a.name, b.name));

        this.totalFiltered = data.totalElements;
        this.totalGeneral = data.totalGeneral;
        this.totalSelected = data.totalSelected;
        this.totalPages = data.totalPages;

        this.$emit('update-pagination', this.pageableDTO);
      } finally {
        this.loading = false;
      }
    },

    async updateSearchInput(search) {
      if (typeof search === 'object') {
        this.searchQuery = search.target.value;
      } else {
        this.searchQuery = search;
      }

      this.pageableDTO.page = 0;
      await this.fetchItems();
    },
    async clearInput() {
      this.searchQuery = '';
      this.pageableDTO.page = 0;
      await this.fetchItems();
    },
    async selectAllAction() {
      await this.fetchItems();
    },
    async previousPage() {
      if (this.pageableDTO.page > 0) {
        this.pageableDTO.page--;
        await this.fetchItems();
      }
    },
    async nextPage() {
      if (this.pageableDTO.page < this.totalPages - 1) {
        this.pageableDTO.page++;
        await this.fetchItems();
      }
    },
    translate(key) {
      return LanguageService.getKey3(key);
    },
    updateTotal(e) {
      if (e) {
        this.totalSelected++;
      } else {
        this.totalSelected--;
      }

      setTimeout(() => {
        if (this.selected.length === 0) {
          this.selectAll = false;
        } else if (this.selected.length > 0 && this.selected.length !== this.totalFiltered) {
          this.selectAll = 'undefined';
        } else if (this.selected.length === this.totalFiltered) {
          this.selectAll = true;
        }
      }, 1000);
    },
    byPage() {
      const items = this.items.map(item => item.id);
      const selected = this.selected.map(item => item.id);
      const commonItems = items.filter(item => selected.includes(item));

      return commonItems.length;
    }
  }
};
