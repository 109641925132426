import i18n from '@/plugins/i18n'
import * as types from './mutations-type.js'
import AppointmentsService from '../../../services/AppointmentsService'
import StorageTypesService from '../../../services/StorageTypesService'
import StructuresService from '../../../services/StructuresService'
import moment from 'moment'
import LogsService from '../../../services/LogsService'

export default {
  loadingON({ commit }) {
    commit(types.SET_LOADING_ON, true)
  },
  loadingOFF({ commit }) {
    commit(types.SET_LOADING_OFF, false)
  },
  async findRecordById({ dispatch, commit, state }, [id, toast]) {
    dispatch('loadingON')
    try {
      const { data } = await AppointmentsService.recordById(id)
      commit(types.SET_RECORD, data)
      dispatch('loadingOFF')
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }
  },
  async findRecordsByIds({ dispatch, commit, state }, [ids, toast]) {
    dispatch('loadingON')
    state.recordsForPrint = []
    try {
      const { data } = await AppointmentsService.recordsByIds(ids)
      commit(types.SET_RECORDS_FOR_PRINT, data)
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    } finally {
      dispatch('loadingOFF')
    }
  },

  async fetchListAppointmentsByCompanyPaginated({ dispatch, commit, state }, [paginationAndFilter, source, toast]) {
    state.recordsByCompany = []
    dispatch('loadingON')
    try {
      const { data } = await AppointmentsService.appointmentsByCompanyPaginated(paginationAndFilter, source)
      if (paginationAndFilter.storageDeviceTypeDTO) {
        data.sdt = paginationAndFilter.storage_device_types_ids[0]
      }
      commit(types.SET_APPOINTMENTS_BY_COMPANY_PAGINATED, data)
      commit(types.SET_REQUEST, false)
      commit(types.SET_SOURCE, null)
    } catch (error) {
      commit(types.SET_REQUEST, true)
      commit(types.SET_SOURCE, null)
      if (error.code !== 'ERR_CANCELED') {
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
      }
    } finally {
      dispatch('loadingOFF')
    }
  },

  async fetchListRecordsBySearch({ dispatch, commit, state }, [filter, source, toast]) {
    state.recordsByCompany = []
    try {
      const { data } = await AppointmentsService.filterSearch(filter, source)
      if (filter.storageDeviceTypeDTO) {
        data.sdt = filter.storageDeviceTypeDTO['id']
      }
      commit(types.SET_APPOINTMENTS_BY_COMPANY_PAGINATED, data)
      commit(types.SET_REQUEST, false)
      commit(types.SET_SOURCE, null)
    } catch (error) {
      commit(types.SET_REQUEST, true)
      commit(types.SET_SOURCE, null)
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }
  },
  async fetchListAppointmentsByCompanyPaginatedWithoutState({
                                                              dispatch,
                                                              commit,
                                                              state,
                                                            }, [toast, paginationAndFilter, source]) {
    dispatch('loadingON')
    try {
      const { data } = await AppointmentsService.appointmentsByCompanyPaginated(paginationAndFilter, source)
      commit(types.SET_APPOINTMENTS_BY_COMPANY_PAGINATED_WITHOUT_STAGE, data)
      dispatch('loadingOFF')
    } catch (error) {
      if (error.code !== 'ERR_CANCELED')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }

  },
  async fetchListAppointmentsByCompany({ dispatch, commit, state }, [toast, companyID]) {
    try {
      const { data } = await AppointmentsService.appointmentsByCompany(companyID)
      commit(types.SET_APPOINTMENTS_BY_COMPANY, data)
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }
  },
  async fetchListAppointmentsByCompanyFiltered({ dispatch, commit, state }, [toast, filter]) {
    state.recordsByCompanyFiltered = []
    try {
      const { data } = await AppointmentsService.appointmentsByCompanyFiltered(filter)
      commit(types.SET_APPOINTMENTS_BY_COMPANY_FILTERED, data)
      dispatch('loadingOFF')
      if (!data.length > 0) {
        toast.info(i18n.t('records.notification.recordsCero'), {
          icon: 'mdi-information-slab-circle',
          queueable: true
        })
      }
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })

      dispatch('loadingOFF')
    }
  },
  async fetchCantToExport({ dispatch, commit, state }, [toast, filter]) {
    state.cantToExport = 0
    try {
      const { data } = await AppointmentsService.cantToExport(filter)
      commit(types.SET_CANT_TO_EXPORT, data)
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }
  },
  async saveRecord({ dispatch, commit, state }, [record, document, documents, editOrSave, pre_record_id, toast]) {
    dispatch('loadingON')
    try {
      const { data } = await AppointmentsService.save(record, document, documents, editOrSave, pre_record_id)
      if(data && data.success!=null && data.success === false){
        commit(types.CHECK_BURDENS, !data.success)
        commit(types.CHECK_BURDENS_DATA, data.data)
      } else{
        toast.success(i18n.t('records.notification.save'), {
          icon: 'mdi-check-circle',
          queueable: true
        })
      }

    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.saveError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }
  },
  async deleteRecord({ commit, state }, [id, toast]) {
    try {
      await AppointmentsService.delete(id)
      toast.success(i18n.t('records.notification.deleted'), {
        icon: 'mdi-check-circle',
        queueable: true
      })
    } catch (error) {
      console.log(error.response)
      if (error.response.data.status !== 406) {
        if (error.message !== 'cancelRequest')
          toast.error(i18n.t('records.notification.deletedError'), {
            icon: "mdi-alert-circle",
            queueable: true
          })
      } else {
        if (error.response.data.message === 'RESTRICTED_DELETE_BY_TRACEABILITY') {
          toast.info(i18n.t('records.notification.recordInUseByReport'), {
            icon: 'mdi-information-slab-circle',
            queueable: true
          })
        } else {
          toast.error(i18n.t('records.notification.deletedError'), {
            icon: "mdi-alert-circle",
            queueable: true
          })
        }
      }
    }
  },
  async exportRecords({ commit, state }, [filters, records, type, toast, isAsync = false]) {
    try {
      const { data } = await AppointmentsService.export(filters, records, type, isAsync)

      if (!isAsync) {
        const FileSaver = require('file-saver')
        switch (type) {
          case 'excel':
            FileSaver.saveAs(data, `${i18n.t('modules.name.Record').toUpperCase()}.xlsx`)
            return
          case 'pdf':
            FileSaver.saveAs(data, `${i18n.t('modules.name.Record').toUpperCase()}.pdf`)
            return
          case 'pdfTraceability':
            FileSaver.saveAs(data, `report-${filters.type}-${moment(Date.now()).format('YYYYMMDD')}.pdf`)
            return
        }
      } else {
        toast.success(i18n.t('general.notification.exportAsyncRecords'), {
          icon: 'mdi-check-circle',
          queueable: true
        })
      }
    } catch (err) {
      if (err.response.status === 412) {
        toast.info(i18n.t('records.notification.recordsCero'), {
          icon: 'mdi-information-slab-circle',
          queueable: true
        })
      } else if (err.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.exportError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }
  },
  async exportErrors({ commit, state }, [recordId, lang, toast]) {
    try {
      const { data } = await AppointmentsService.exportErrors(recordId, lang)

      const FileSaver = require('file-saver')
      FileSaver.saveAs(data, `record-errors-${moment(Date.now()).format('YYYYMMDD')}.xlsx`)
    } catch (err) {
      if (err.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.exportError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }
  },
  importRecords: async function ({ commit, state }, [options, toast]) {
    try {
      const data = await AppointmentsService.import(options);

      if (!options.isAsync) {
        if (data instanceof Blob) {
          const FileSaver = require('file-saver');
          FileSaver.saveAs(data, `${i18n.t('modules.name.Record').toUpperCase()}.xlsx`);

          toast.error(i18n.t('records.notification.importError'), {
            icon: "mdi-alert-circle",
            queueable: true
          });
        } else {
          toast.success(i18n.t('records.notification.importSuccess'), {
            icon: 'mdi-check-circle',
            queueable: true
          });
        }
      } else {
        toast.success(i18n.t('general.notification.importAsyncRecords'), {
          queueable: true,
          icon: 'mdi-check-circle',
        });
      }
    } catch (e) {
      console.log(e)
      if (e.message !== 'cancelRequest') {
        toast.error(i18n.t('records.notification.importError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
      }
    }
  },
  async fetchTemplate({ commit, state }, [options, name, toast]) {
    try {
      const { data } = await AppointmentsService.fetchTemplate(options)

      const FileSaver = require('file-saver')
      const blob = new Blob([data], {
        type: 'application/xlsx',
      })
      FileSaver.saveAs(blob, `${name}-template.xlsx`)

      toast.success(i18n.t('general.notification.downloadTemplateSuccess'), {
        icon: 'mdi-check-circle',
        queueable: true
      })
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('general.notification.downloadTemplateError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }
  },
  async appointmentsByProcess({ dispatch, commit, state }, [toast, process_id]) {
    dispatch('loadingON')
    try {
      const { data } = await AppointmentsService.appointmentsByProcess(process_id)
      if (!data.length > 0) {
        toast.info(i18n.t('records.notification.recordsCero'), {
          icon: 'mdi-information-slab-circle',
          queueable: true
        })
      }
      commit(types.SET_APPOINTMENTS_BY_PROCESS, data)
      dispatch('loadingOFF')
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }

  },
  async appointmentsByLote({ dispatch, commit, state }, [toast, filters, type]) {
    try {
      const { data } = await AppointmentsService.getAppointmentsIDS(filters, type)

      if (!data.recordIds.length > 0) {
        toast.error(i18n.t('records.notification.recordsFilterCero'), {
          icon: "mdi-alert-circle",
          queueable: true,
          color: 'gray',
        })
      }

      localStorage.setItem('recordIds', JSON.stringify(data.recordIds))

      if (filters.generateNew)
        localStorage.setItem('documentIds', JSON.stringify(data.documentIds))
    } catch (error) {
      localStorage.removeItem('recordIds')
      commit(types.SET_REQUEST, true)
      commit(types.SET_SOURCE, null)
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }

  },
  async fetchListStructures({ commit, state }, [profile, toast]) {
    try {
      const company = profile.company_id
      const { data } = await StructuresService.structuresByCompany(company)
      commit(types.SET_STRUCTURES_LIST, data)
    } catch (error) {
      if (error.message !== 'cancelRequest')
        toast.error(i18n.t('records.notification.listError'), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
    }
  },
  async fetchEvidencesByRecord({ commit }, [id, toast]) {
    try {
      const { data } = await AppointmentsService.fetchEvidencesByRecord(id)

      if(data && data.length > 0) {
        commit(types.SET_EVIDENCES, data)
      }
    } catch (error) {
      toast.error(i18n.t('records.notification.errorLoadEvidences'), {
        icon: "mdi-alert-circle",
        queueable: true,
      })
    }
  },
  async updateEvidencesByRecord({ }, [id, json, currents, documents, toast]) {
    try {
      await AppointmentsService.updateEvidencesByRecord(id, json, currents, documents)
      toast.success(i18n.t('records.notification.successSaveEvidences'), {
        queueable: true,
        icon: 'mdi-check-circle',
      })
    } catch (error) {
      console.log(error)
      toast.error(i18n.t('records.notification.errorSaveEvidences'), {
        icon: "mdi-alert-circle",
        queueable: true,
      })
    }
  },
  async checkBurdens({ commit, state }, [options, toast]) {
    try {
      const { data } = await AppointmentsService.checkBurdens(options)

      const FileSaver = require('file-saver')
      FileSaver.saveAs(data, `check-fardos-${moment(Date.now()).format('YYYYMMDD')}.xlsx`)

    } catch (err) {
      if (err.response.status === 406) {
        toast.error(i18n.t(err.response.message), {
          queueable: true,
          icon: "mdi-alert-circle",
        })
      } else if (err.response.status === 404){
        toast.error(i18n.t(err.response.message), {
          queueable: true,
          icon: "mdi-alert-circle",
        })
      } else if (error.message !== 'cancelRequest'){
        toast.error("Generic Erro", {
          queueable: true,
          icon: "mdi-alert-circle",
        })
      }

    }
  },
  async findLogByRecordId({ dispatch, commit, state }, [id, toast]) {
    dispatch('loadingON')
    try {
      const { data } = await LogsService.recordById(id)
      commit(types.SET_RECORD_LOG, data)
      dispatch('loadingOFF')
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Log not register')
      } else if (error.message !== 'cancelRequest') {
        toast.error(i18n.t('records.notification.listError'), {
          queueable: true,
          icon: "mdi-alert-circle",
        })
      }
    }
  },
}
